import React, { useRef, useState } from "react";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export const fragment = graphql`
    fragment subAccordion on ContentfulSubAccordion {
        id
        title
        body {
            raw
        }
    }
`;

const SubAccordion = ({ cx, formatContentfulRichText, ...props }) => {
    // Ref for adjusting CSS
    const bodyRef = useRef();

    // State for toggling class
    const [isOpen, setIsOpen] = useState(false);

    // Event function to open/close the accordion
    const handleAccordion = (e) => {
        // Body element to be opened/closed
        const body = bodyRef.current;

        // Change state
        setIsOpen(!isOpen);

        // Opens/closes the accordion via CSS
        body.style.height ? (body.style.height = null) : (body.style.height = body.scrollHeight + "px");
    };

    const openClass = isOpen ? cx("open") : "";

    return (
        <div className={`${cx("subAccordion", openClass)} subAccordion`}>
            <button
                className={cx("titleButton")}
                onClick={handleAccordion}
                aria-expanded={isOpen}
                aria-controls={props.item.id}
                tabIndex={0}
            >
                <h3>
                    {props.item.title}
                    <div className={cx("cross")}></div>
                </h3>
            </button>
            <div className={cx("body")} ref={bodyRef} aria-labelledby={props.item.id}>
                <div>{renderRichText(props.item.body, formatContentfulRichText)}</div>
            </div>
        </div>
    );
};

export default SubAccordion;
