import React from "react";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export const fragment = graphql`
    fragment subRichText on ContentfulSubRichText {
        id
        contentBody {
            raw
            references {
                ...RTEReferences
            }
        }
    }
`;

const SubRichText = ({ formatContentfulRichText, ...props }) => {
    // Destructure props
    const {
        item: { contentBody },
    } = props;

    // Setup card description
    const contentBlock = contentBody && contentBody.raw ? renderRichText(contentBody, formatContentfulRichText()) : "";

    return contentBlock.length ? (
        <div className={"contentOuter"}>
            <div className={"subRich"}>{contentBlock}</div>
        </div>
    ) : (
        <></>
    );
};

export default SubRichText;
