import React from "react";
import { graphql } from "gatsby";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import Arrow from "@v4/gatsby-theme-talend/src/assets/svg/arrow.inline.svg";

export const fragment = graphql`
    fragment customerCard on ContentfulCustomerCard {
        id
        cardBackgroundImage {
            # fluid (maxWidth: 735, transformations: "f_auto,q_auto,c_crop,g_center,w_800")
            public_id
            width
            height
            format
        }
        cardLogo {
            # fixed (width: 345, transformations: "q_auto,f_auto")
            public_id
            width
            height
            format
        }
        cardLogoAltText
        overlayColorHex
        cardContent {
            raw
            references {
                ...RTEReferences
            }
        }
        url
        pageLink {
            ...PageLink
        }
    }
`;

const CustomerCard = ({ cx, formatContentfulRichText, ...props }) => {
    // Destructure props
    const {
        item: { cardContent, cardLogo, cardLogoAltText, cardBackgroundImage, overlayColorHex, url, pageLink },
    } = props;

    // Determine whether card is linked or not
    const to = url || (pageLink && pageLink.slug) || null;

    // Setup images
    const logo = cardLogo && cardLogo[0] ? cardLogo[0] : false;
    const bgImage = cardBackgroundImage && cardBackgroundImage[0] ? cardBackgroundImage[0] : false;

    // Setup card description
    const cardBody = cardContent && cardContent.raw ? renderRichText(cardContent, formatContentfulRichText()) : "";

    // Logo Image Element
    const logoEl = logo && (
        <Image
            placeholderStyle={{ filter: `blur(10px)` }}
            image={logo}
            imgStyle={{ objectFit: "contain", width: "100%", height: "100%", objectPosition: "center center" }}
            className={cx("logo")}
            style={{ maxWidth: "55%", maxHeight: "25%", width: "100%", height: "100%" }}
            alt={cardLogoAltText}
        />
    );

    // Background Image Element
    const bgImageEl = bgImage && (
        <Image
            placeholderStyle={{ filter: `blur(10px)` }}
            image={bgImage}
            width={"735"} // The max presentational width of the image at any given breakpoint
            className={cx("curtain")}
            style={{ width: "100%", height: "100%", position: "absolute" }} // Style applied to the image's wrapper
            imgStyle={{
                // Styles applied to the image directly
                objectFit: "cover",
                objectPosition: "center",
                minWidth: "100%",
                minHeight: "100%",
            }}
            transforms={["f_auto", "q_auto", "c_crop", "g_center"]}
        />
    );

    // Content Element
    const cardContentEl = (showArrow) =>
        cardBody && (
            <div className={cx("contentWrapper")}>
                {logoEl}
                <div className={cx("copy")}>
                    {cardBody}
                    {showArrow && (
                        <div className={cx("arrow")}>
                            <Arrow />
                        </div>
                    )}
                </div>
            </div>
        );

    // Customer Card Element
    const card = (showArrow) => (
        <>
            {bgImageEl}
            {cardContentEl(showArrow)}
        </>
    );

    return (
        <>
            {to ? (
                <Link
                    to={to}
                    className={cx("customerCardOuter", "customerCardURL")}
                    style={{ backgroundColor: `#${overlayColorHex}` }}
                >
                    {card(true)}
                </Link>
            ) : (
                <div className={cx("customerCardOuter")} style={{ backgroundColor: `#${overlayColorHex}` }}>
                    {card(false)}
                </div>
            )}
        </>
    );
};

export default CustomerCard;
