import React from "react";
import BaseSubIcon from "@v4/gatsby-theme-talend/src/components/modules/multiItem/SubIcon";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import CTA from "@v4/talend/src/components/cta/Cta";
import classNames from "classnames/bind";
import * as iconStyles from "./subIcon.module.css";

const cx = classNames.bind(iconStyles);

const SubIcon = (props) => (
    <BaseSubIcon cx={cx} CTA={CTA} formatContentfulRichText={formatContentfulRichText} {...props} />
);

export default SubIcon;
