import React from "react";
import BaseSubAccordion from "@v4/gatsby-theme-talend/src/components/modules/multiItem/SubAccordion";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import classNames from "classnames/bind";
import * as cardStyles from "./subAccordion.module.css";

const cx = classNames.bind(cardStyles);

const SubAccordion = (props) => (
    <BaseSubAccordion cx={cx} formatContentfulRichText={formatContentfulRichText} {...props} />
);

export default SubAccordion;
