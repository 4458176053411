import React from "react";
import BaseSubImage from "@v4/gatsby-theme-talend/src/components/modules/multiItem/SubImage";

import classNames from "classnames/bind";
import * as imageStyles from "./subImage.module.css";

const cx = classNames.bind(imageStyles);

const SubImage = (props) => <BaseSubImage cx={cx} {...props} />;

export default SubImage;
