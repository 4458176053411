export const multiItemTranslations = {
    en: {
        showAll: "Show all",
        showFewer: "Show fewer",
    },
    "en-GB": {
        showAll: "Show all",
        showFewer: "Show fewer",
    },
    fr: {
        showAll: "Afficher tout",
        showFewer: "Montrer moins",
    },
    de: {
        showAll: "Zeige alles",
        showFewer: "Weniger anzeigen",
    },
    it: {
        showAll: "Mostra tutto",
        showFewer: "Mostra meno",
    },
    ja: {
        showAll: "すべて表示する",
        showFewer: "より少ないショー",
    },
};
