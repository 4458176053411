import React from "react";
import { graphql } from "gatsby";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import Link from "@v4/gatsby-theme-talend/src/components/Link";

export const fragment = graphql`
    fragment subImage on ContentfulSubImage {
        id
        imageAltText
        name
        image {
            width
            height
            public_id
            format
        }
        url
        pageLink {
            ...PageLink
        }
    }
`;

const SubImage = ({ cx, ...props }) => {
    const {
        item: { imageAltText, image, url, pageLink },
    } = props;

    // Determine whether the image is linked or not
    const to = url || (pageLink && pageLink.slug) || null;

    const imageEl = (
        <>
            {image && image[0] && (
                <Image
                    className={cx("imageContainer")}
                    placeholderStyle={{ filter: `blur(10px)` }}
                    imgStyle={{
                        objectFit: "contain",
                    }}
                    image={image[0]}
                    transformations={"c_pad"}
                    alt={imageAltText}
                />
            )}
        </>
    );

    return (
        <div className={cx("imageOuter")}>
            {to ? (
                <Link to={to} className={cx(`imageURL`)}>
                    {imageEl}
                </Link>
            ) : (
                imageEl
            )}
        </div>
    );
};

export default SubImage;
