import React from "react";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";

export const fragment = graphql`
    fragment subIcon on ContentfulSubIcon {
        id
        name
        style
        bgHex
        header
        headerAlignment
        description {
            raw
            references {
                ...RTEReferences
            }
        }
        iconAltText
        naturalSizing: iconSizing
        iconAlignment
        icon {
            # fixed (width: 290, transformations: "c_fit")
            public_id
            format
            width
            height
        }
        cta {
            ...Cta
        }
    }
`;

const SubIcon = ({ cx, CTA, formatContentfulRichText, ...props }) => {
    const {
        item: {
            style,
            bgHex,
            header,
            headerAlignment,
            description,
            iconAltText,
            icon,
            naturalSizing,
            iconAlignment,
            cta,
        },
    } = props;

    // Alignment options for cx classes:
    const hAlign = headerAlignment ? "textAlign" + headerAlignment : "textAlignCenter";
    const iconAlign = iconAlignment ? iconAlignment.toLowerCase() : "center";
    const iconClass = naturalSizing && naturalSizing.length ? "naturalSizing" : "iconSizing";

    // Boxed or Not
    const colStyles = style ? style.toLowerCase() : "normal";

    // If boxed it may have a BG color
    const bgColor = colStyles === "boxed" && bgHex ? { background: "#" + bgHex } : {};

    // Setup image
    const image = icon && icon[0] ? icon[0] : false;

    // Setup card description
    const desc = description && description.raw ? renderRichText(description, formatContentfulRichText()) : "";

    // Produce image element
    const imageEl = (
        <div className={cx("subIcon", image.format, iconAlign, iconClass)}>
            {image && (
                <Image
                    placeholderStyle={{ filter: `blur(10px)` }}
                    image={image}
                    style={naturalSizing && naturalSizing.length ? {} : { height: "80px" }}
                    imgStyle={
                        naturalSizing && naturalSizing.length
                            ? {}
                            : { height: "80px", maxWidth: "100%", width: "auto", objectFit: "contain" }
                    }
                    alt={iconAltText}
                />
            )}
        </div>
    );

    // Produce main card markup
    const contentEl = (
        <div className={cx("subIconContent")}>
            {header && <h3 className={hAlign}>{header}</h3>}
            {desc}
            {cta && (
                <div className={cx(`ctaSection`)}>
                    {cta.map((cta, index) => (
                        <CTA key={`cta-${index}`} {...cta} />
                    ))}
                </div>
            )}
        </div>
    );

    // Main card element
    const subIconContainer = (
        <div className={cx("subIconContainer", `${colStyles}`)} style={bgColor}>
            {image && imageEl}
            {contentEl}
        </div>
    );

    return <div className={cx("subIconOuter")}>{subIconContainer}</div>;
};

export default SubIcon;
