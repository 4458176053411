import React, { useState } from "react";
import { graphql } from "gatsby";
import scrollToElement from "scroll-to-element";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import stylingOptions from "@v4/utils/stylingOptions";
import getTextAlignClassName from "@v4/utils/getTextAlignClassName";
import { useI18n } from "@v4/utils/i18nContext";
import { multiItemTranslations } from "@v4/gatsby-theme-talend/src/translations/multiItem";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import PlusSVG from "@v4/gatsby-theme-talend/src/assets/svg/plus-symbol--circle.inline.svg";
import MinusSVG from "@v4/gatsby-theme-talend/src/assets/svg/minus-symbol--circle.inline.svg";

export const fragment = graphql`
    fragment MultiItem on ContentfulMultiItem {
        id
        name
        stylingOptions {
            ...stylingOptions
        }
        headerIcon {
            # fixed (height: 60)
            width
            height
            public_id
            format
        }
        headerIconAltText
        itemHeading
        itemHeadingAlignment
        itemParagraph {
            raw
            references {
                ...RTEReferences
            }
        }
        itemParagraphAlignment
        copyWidth
        itemsPerRow
        rowsShown
        showMoreLabel
        showFewerLabel
        itemsAlignment
        items {
            __typename
            ... on Node {
                ...subCard
                ...subImage
                ...customerCard
                ...subIcon
                ...subRichText
                ...subAccordion
            }
        }
        cta {
            ...Cta
        }
    }
`;

const MultiItem = ({ cx, itemType, formatContentfulRichText, CTA, ...props }) => {
    const allItems = props.items;
    const gridType = props?.items?.[0]?.__typename.replace("Contentful", "").toLowerCase();
    const bgElStyles = { height: "60%", bottom: "0", top: "unset" };
    const moduleStyleOpts = stylingOptions({ ...props.stylingOptions, bgElStyles: bgElStyles }, cx);
    const headingAlignment = getTextAlignClassName(props.itemHeadingAlignment, "text-center");
    const paragraphAlignment = getTextAlignClassName(props.itemParagraphAlignment, "text-center");
    const itemsAlignment = props.itemsAlignment ? props.itemsAlignment : "Center";
    const itemsPerRow = gridType !== "subaccordion" ? (props.itemsPerRow ? props.itemsPerRow : 3) : 1;

    // Calculate how many items are to be shown initially, or flag as false, which disables the "Show all" feature
    // "rowsShown" can be passed as "false" in order to disable the toggle feature (like in the Customer template)
    const itemsShownCount =
        props.rowsShown !== null && props.rowsShown !== false ? itemsPerRow * props.rowsShown : false;

    // Slice items based on the itemsShownCount when "show more" feature is enabled
    const slicedItems = itemsShownCount ? allItems.slice(0, itemsShownCount) : false;

    // State object for holding list object for "show more" feature
    const [itemsObject, setItemsObject] = useState(
        // Show all items unless a value for the "items shown" option is in props, then show the initial items
        itemsShownCount !== false ? slicedItems : allItems
    );

    // Bool to determine whether the "show all" feature is open or closed
    const isOpen = itemsObject && itemsObject.length === allItems.length;

    // Function to handle toggle of the "show all" and "show fewer"
    const handleShowToggle = () => {
        // If we're already showing the max number of items, show the sliced items, otherwise show all items
        setItemsObject(isOpen ? slicedItems : allItems);

        // Scroll the user upwards if we're closing
        if (isOpen === true) scrollToElement(`#${props.moduleId}`, { offset: -60, ease: "in-out-cube", duration: 500 });
    };

    // Copy width option for cx classes
    const copyWidth = props.copyWidth ? `copyWidth${String(props.copyWidth).replace("%", "")}` : null;

    // Setup image
    const image = props.headerIcon && props.headerIcon[0] ? props.headerIcon[0] : false;
    const imageStyles = { height: 60 };

    // Handle rich text
    const paragraph =
        props.itemParagraph && props.itemParagraph.raw
            ? renderRichText(props.itemParagraph, formatContentfulRichText())
            : "";

    // Produce header icon element
    const imageEl = image && (
        <div className={cx("headerIcon")}>
            <Image
                placeholderStyle={{ filter: `blur(10px)` }}
                image={image}
                height={"60"}
                style={imageStyles}
                imgStyle={{ objectFit: "contain" }}
                alt={props.headerIconAltText}
            />
        </div>
    );

    // Set up localization context
    const { i18n } = useI18n();

    // Define labels with translated fallback strings
    const showAllLabel = props.showMoreLabel ?? multiItemTranslations[i18n.curr.langCode].showAll;
    const showFewerLabel = props.showFewerLabel ?? multiItemTranslations[i18n.curr.langCode].showFewer;

    // Button for toggle, with "show all" / "show fewer" labels
    const toggleButton = (
        <button className={cx("showAllButton")} onClick={handleShowToggle}>
            {isOpen ? (
                <>
                    <span>{showFewerLabel}</span>
                    <MinusSVG className={cx("showAllButtonMinus")} />
                </>
            ) : (
                <>
                    <span>{showAllLabel}</span>
                    <PlusSVG className={cx("showAllButtonPlus")} />
                </>
            )}
        </button>
    );

    return (
        <section
            id={props.moduleId}
            className={`${cx("multiItemModule", `${gridType}`)} ${moduleStyleOpts.classString}`}
        >
            {moduleStyleOpts && moduleStyleOpts.borderTopEl}
            <div className={cx(`container`)}>
                {imageEl}
                {props.itemHeading && <h2 className={cx(`multiItemTitle`, headingAlignment)}>{props.itemHeading}</h2>}
                {props.itemParagraph && paragraph && (
                    <div className={cx(paragraphAlignment, copyWidth)}>{paragraph}</div>
                )}
                <div className={cx(`${gridType}Grid`, "itemWrapper", `col${itemsPerRow}`, `align${itemsAlignment}`)}>
                    {itemsObject &&
                        itemsObject.map((item, index) => {
                            return <React.Fragment key={`item-${index}`}>{itemType(item)}</React.Fragment>;
                        })}
                </div>
                {itemsShownCount !== false && toggleButton}
                {props.cta && (
                    <div className={cx(`ctaSection`)}>
                        {props.cta.map((cta, index) => (
                            <CTA key={`cta-${index}`} {...cta} />
                        ))}
                    </div>
                )}
            </div>
            {moduleStyleOpts && moduleStyleOpts.bgImageEl}
            {moduleStyleOpts && moduleStyleOpts.borderBottomEl}
        </section>
    );
};

export default MultiItem;
