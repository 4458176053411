import React from "react";
import { graphql } from "gatsby";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import Arrow from "@v4/gatsby-theme-talend/src/assets/svg/arrow.inline.svg";

export const fragment = graphql`
    fragment subCard on ContentfulSubCard {
        id
        cardType
        cardHeader
        cardDescription {
            raw
            references {
                ...RTEReferences
            }
        }
        cardIcon {
            # fixed (width: 50)
            public_id
            width
            height
            format
        }
        cardIconAltText
        buttonText
        url
        pageLink {
            ...PageLink
        }
    }
`;

const Tile = ({ cx, image, alt, header, description, to }) => {
    const imageStyles = { width: 50, height: "auto" };

    const innerTile = (
        <div className={cx("tile")}>
            {image && (
                <div className={cx("tileIcon")}>
                    <Image placeholderStyle={{ filter: `blur(10px)` }} image={image} imgStyle={imageStyles} alt={alt} />
                </div>
            )}

            <div className={cx("tileContent")}>
                <h3>{header}</h3>
                {description}
            </div>

            {to && (
                <div className={cx("tileLinkArrow")}>
                    <Arrow />
                </div>
            )}
        </div>
    );

    return (
        <div className={cx("subCard", "tileOuter")}>
            {to ? (
                <Link to={to} className={cx(`tileURL`)}>
                    {innerTile}
                </Link>
            ) : (
                innerTile
            )}
        </div>
    );
};

const Card = ({ cx, image, alt, header, description, to, buttonText }) => (
    <div className="subCard flex flex-col border rounded-lg mb-4">
        <div className="cardImage">
            <Image placeholderStyle={{ filter: `blur(10px)` }} image={image} alt={alt} />
        </div>
        <div className="px-2 py-2.5 flex flex-col flex-grow">
            <div className="cardContent mb-auto">
                <h3 className="text-h4">{header}</h3>
                {description && <p className="text-subhead pt-1">{description}</p>}
            </div>
            {to && <Link className={cx("cardButton", "self-start", "mt-2.5")}>{buttonText}</Link>}
        </div>
    </div>
);

const SubCard = ({ cx, formatContentfulRichText, ...props }) => {
    // Destructure props
    const {
        item: { cardType, cardIcon, cardHeader, cardDescription, url, buttonText, cardIconAltText, pageLink },
    } = props;

    // Determine whether card is linked or not
    const to = url || (pageLink && pageLink.slug) || null;

    // Setup image
    const image = cardIcon && cardIcon[0] ? cardIcon[0] : false;

    // Setup card description
    const description =
        cardDescription && cardDescription.raw ? renderRichText(cardDescription, formatContentfulRichText()) : "";

    const subProps = {
        cx,
        image,
        alt: cardIconAltText,
        header: cardHeader,
        description,
        to,
        buttonText,
    };

    if (cardType === "Card") return <Card {...subProps} />;
    else return <Tile {...subProps} />;
};

export default SubCard;
