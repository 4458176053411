// extracted by mini-css-extract-plugin
export var boxed = "subIcon-module--boxed--9c392";
export var ctaSection = "subIcon-module--ctaSection--1287c";
export var isImgTag = "subIcon-module--isImgTag--028e6";
export var left = "subIcon-module--left--f5682";
export var right = "subIcon-module--right--44f58";
export var subIcon = "subIcon-module--subIcon--f9aee";
export var subIconContainer = "subIcon-module--subIconContainer--f7e98";
export var subIconContent = "subIcon-module--subIconContent--1cf63";
export var subIconOuter = "subIcon-module--subIconOuter--4e037";
export var svg = "subIcon-module--svg--18902";